import classNames from 'classnames';
import * as React from 'react';
import Button from '../Button';
import Text from '../Text';
import TrustedBy from '../TrustedBy';
import './style.scss';

const CompanyHeader: React.SFC = () => {
  return (
    <div className={'company-header'}>
      <div className={'company-header__content'}>
        <Text
          type="label"
          color="dark"
          weight="bold"
          className={classNames('company-header__content__text', 'h2')}
        >
          With our Code
        </Text>
        <Text
          type="label"
          color="dark"
          weight="bold"
          className={classNames(
            'company-header__content__text',
            'company-header__content__text-second',
            'h2'
          )}
        >
          We Make the World.
        </Text>
        <Text
          type="label"
          color="dark"
          weight="demi-bold"
          align="left"
          dim="30"
          className={classNames(
            'company-header__content__text',
            'company-header__content__text-last',
            'h6'
          )}
        >
          — Buddha ft. Stormotion
        </Text>

        <Button
          href="/contact-us/"
          size="medium"
          color="secondary"
          className={'company-header__content__button'}
        >
          Contact Us
        </Button>
      </div>

      <TrustedBy />
    </div>
  );
};

export default CompanyHeader;
