import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { mapObjIndexed } from 'ramda';

interface Images {
  image: IGatsbyImageData;
  image1: IGatsbyImageData;
  image2: IGatsbyImageData;
  image3: IGatsbyImageData;
}

const useMissionImages = () => {
  const data = useStaticQuery<Images>(graphql`
    {
      image: file(relativePath: { eq: "mission.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 510
            height: 410
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      image1: file(relativePath: { eq: "mission1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 510
            height: 566
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      image2: file(relativePath: { eq: "mission2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            height: 464
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      image3: file(relativePath: { eq: "mission3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 510
            height: 744
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  return mapObjIndexed(getImage, data);
};

export default useMissionImages;
