import React from 'react';
import AboutUsPage from '../components/AboutUsPage';
import CompanyHeader from '../components/CompanyHeader';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import SEO from '../components/seo';
import { HeadProps, graphql } from 'gatsby';
import { AboutUsPageType } from '../utils/aboutUsTypes';
import { LocaleNode } from 'gatsby-plugin-react-i18next/dist/types';
import { SITE_URL } from '../theme/constants';

type DataProps = {
  sanityCareersPage: AboutUsPageType;
  locales: { edges: Array<{ node: LocaleNode }> };
};

const AboutUsStructuredData = (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'AboutPage',
      url: `${SITE_URL}/about-us/`,
      name: 'About Us',
    })}
  </script>
);

const AboutUs: React.FC<HeadProps<DataProps>> = ({
  data: { sanityCareersPage },
}) => {
  return (
    <Layout>
      <Navigation headerBackgroundColor="light" />
      <Header>
        <CompanyHeader />
      </Header>
      <AboutUsPage {...sanityCareersPage} />
      <Footer noFooterCard={false} />
    </Layout>
  );
};

export const query = graphql`
  query CareersInfo($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sanityCareersPage {
      teammateFeedbacks {
        title
        author
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        body
      }
      workflowPoints {
        icon
        circleColor
        title
        description
        body
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Custom Web & Mobile Development & Engineering Services | About us"
    description="We develop Digital Products, which solve real business challenges. By emphasizing with our clients and using our experience - we deliver scalable solutions with a remarkable UX."
  >
    {AboutUsStructuredData}
  </SEO>
);

export default React.memo(AboutUs);
