import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { TeammateFeedbackType } from '../../utils/careersTypes';
import Markdown from '../Markdown';
import QuoteAvatar from '../QuoteAvatar';
import Text from '../Text';
import './style.scss';

export interface TeammateFeedbackProps extends TeammateFeedbackType {
  className?: string;
}

const TeammateFeedback: React.FC<TeammateFeedbackProps> = ({
  className,
  image,
  title,
  author,
  body,
}) => {
  const gatsbyImage = getImage(image?.asset)!;

  const Avatar = useMemo(
    () => <GatsbyImage image={gatsbyImage} alt={title} />,
    [gatsbyImage, title]
  );

  return (
    <div className={`teammate-feedback ${className}`}>
      <div className="feedback-info">
        <QuoteAvatar Image={Avatar} />
        <div className="feedback-info__text-wrapper">
          <Text type="p" className="text-wrapper__author">
            {author}
          </Text>
          <Text type="p" className="text-wrapper__title">
            {title}
          </Text>
        </div>
      </div>
      <Markdown source={body} />
    </div>
  );
};

export default React.memo(TeammateFeedback);
