import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback } from 'react';
import './style.scss';
import QuickFactItem from '../QuickFactItem';

type QuickFactItemType = {
  fact: string;
  quantity: string;
  icon: string;
};

type QuickFactQueryType = {
  sanityQuickFact: {
    items: QuickFactItemType[];
  };
};

type QuickFactsProps = {
  className?: string;
};

const QuickFacts: React.FC<QuickFactsProps> = ({ className = '' }) => {
  const data = useStaticQuery<QuickFactQueryType>(graphql`
    query {
      sanityQuickFact(name: { eq: "default" }) {
        items {
          icon
          fact
          quantity
        }
      }
    }
  `);

  const renderFacts = useCallback(
    ({ fact, quantity, icon }: QuickFactItemType) => {
      return <QuickFactItem fact={fact} quantity={quantity} icon={icon} />;
    },
    []
  );

  const items = data?.sanityQuickFact?.items;

  if (!items?.length) {
    return <></>;
  }

  return (
    <div className={`quick-facts ${className}`}>{items.map(renderFacts)}</div>
  );
};

export default React.memo(QuickFacts);
