import React, { CSSProperties, useMemo } from 'react';
import { WorkflowPointType } from '../../utils/aboutUsTypes';
import Markdown from '../Markdown';
import './style.scss';
import Text from '../Text';

interface WorkflowPointProps extends WorkflowPointType {
  className?: string;
}

const WorkflowPoint: React.FC<WorkflowPointProps> = ({
  title,
  description,
  icon,
  circleColor,
  className,
}) => {
  const circleStyle = useMemo<CSSProperties>(
    () => ({
      backgroundColor: circleColor,
    }),
    [circleColor]
  );

  const iconHTML = useMemo(() => ({ __html: icon }), [icon]);

  return (
    <div className={`${className} workflow-point`}>
      <div
        className="workflow-point__circle"
        style={circleStyle}
        dangerouslySetInnerHTML={iconHTML}
      ></div>
      <div className="benefit-item__text-container">
        <Text type="h6" weight="bold" className="benefit-item__title">
          {title}
        </Text>
        <Markdown source={description} />
      </div>
    </div>
  );
};

export default React.memo(WorkflowPoint);
