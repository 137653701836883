import * as React from 'react';
import './style.scss';
import Text from '../Text';
import { useStaticQuery, graphql } from 'gatsby';
import useMapCountries from '../../hooks/useMapCountries';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { memo, useMemo } from 'react';
interface Images {
  map: {
    publicURL: string;
  };
}

type Country = {
  image: IGatsbyImageData;
  name: string;
};

const Map: React.SFC = () => {
  const data = useStaticQuery<Images>(graphql`
    query {
      map: file(relativePath: { eq: "map.svg" }) {
        publicURL
      }
    }
  `);

  const flags = useMapCountries();

  const countriesData: Country[] = useMemo(
    () => [
      {
        image: flags.cn1!,
        name: 'United States',
      },
      {
        image: flags.cn2!,
        name: 'Germany',
      },
      {
        image: flags.cn3!,
        name: 'Norway',
      },
      {
        image: flags.cn4!,
        name: 'Poland',
      },
      {
        image: flags.cn5!,
        name: 'Great Britain',
      },
      {
        image: flags.cn6!,
        name: 'Ukraine',
      },
      {
        image: flags.cn7!,
        name: 'Netherlands',
      },
      {
        image: flags.cn8!,
        name: 'Canada',
      },
      {
        image: flags.cn9!,
        name: 'Italy',
      },
      {
        image: flags.cn10!,
        name: 'Czech Republic',
      },
      {
        image: flags.cn11!,
        name: 'Australia',
      },
    ],
    [
      flags.cn1,
      flags.cn10,
      flags.cn11,
      flags.cn2,
      flags.cn3,
      flags.cn4,
      flags.cn5,
      flags.cn6,
      flags.cn7,
      flags.cn8,
      flags.cn9,
    ]
  );

  const Countries = useMemo(
    () =>
      countriesData.map((country, index) => {
        return (
          <div key={index} className="map__country">
            <GatsbyImage
              image={country.image}
              className={'map__country-flag'}
              alt={country.name}
            />
            <div className="map__country-name">{country.name}</div>
          </div>
        );
      }),
    [countriesData]
  );

  return (
    <div className="map">
      <div className="map__wrapper">
        <Text type="h4" color="dark" weight="bold" className="map__title">
          Our Clients come from these amazing locations
        </Text>
        <img className="map__background" src={data.map.publicURL} alt="map" />
        <div className="map__countries">{Countries}</div>
      </div>
    </div>
  );
};

export default memo(Map);
