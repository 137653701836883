import React, { useCallback } from 'react';
import { TeammateFeedbackType } from '../../utils/careersTypes';
import TeammateFeedback from '../TeammateFeedback';
import './style.scss';

type TeammateFeedbacksListProps = {
  teammateFeedbacks: TeammateFeedbackType[];
  className?: string;
};

const TeammateFeedbacksList: React.FC<TeammateFeedbacksListProps> = ({
  teammateFeedbacks,
  className,
}) => {
  const renderTeammateFeedback = useCallback<
    (feedback: TeammateFeedbackType, index: number) => JSX.Element
  >((feedback, index) => {
    return <TeammateFeedback {...feedback} className={`feedbacks__item`} />;
  }, []);

  return (
    <div className={`feedbacks ${className}`}>
      {teammateFeedbacks?.map(renderTeammateFeedback)}
    </div>
  );
};

export default React.memo(TeammateFeedbacksList);
