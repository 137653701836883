import React, { useMemo } from 'react';
import Text from '../Text';
import './style.scss';

type QuickFactItemProps = {
  fact: string;
  quantity: string;
  icon: string;
};

const QuickFactItem: React.FC<QuickFactItemProps> = ({
  fact,
  quantity,
  icon,
}) => {
  const iconHtml = useMemo(() => ({ __html: icon }), [icon]);

  return (
    <div className="quick-facts-item">
      <div
        className="quick-facts-item__icon"
        dangerouslySetInnerHTML={iconHtml}
      />

      <Text type="p" weight="bold" className="quick-facts-item__quantity">
        {quantity}
      </Text>

      <Text
        type="p"
        weight="demi-bold"
        color="dark"
        className="quick-facts-item__fact"
      >
        {fact}
      </Text>
    </div>
  );
};

export default React.memo(QuickFactItem);
