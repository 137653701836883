import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Slider from 'react-slick';
import useInstagramPosts from '../../hooks/useInstagramPosts';
import { InstagramPost } from '../../models/Instagram';
import BookmarkIcon from '../icons/BookmarkIcon';
import CommentsIcon from '../icons/CommentsIcon';
import DirectIcon from '../icons/DirectIcon';
import LikeIcon from '../icons/LikeIcon';
import MoreIcon from '../icons/MoreIcon';
import Text from '../Text';
import { GatsbyImage } from 'gatsby-plugin-image';
import './style.scss';
import Button from '../Button';
import Link from '../Link';

function useCurrentWitdh() {
  const getWidth = useCallback(
    () =>
      typeof window !== 'undefined'
        ? window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        : 0,
    []
  );
  const [width, setWidth] = useState(getWidth);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    let timeoutId: number;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => setWidth(getWidth()), 150);
    };
    window.addEventListener('resize', resizeListener, {
      passive: true,
      capture: true,
    });

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
}

const Instagram = () => {
  const { posts, company } = useInstagramPosts();
  const width = useCurrentWitdh();

  const renderPost = useCallback<(post: InstagramPost) => React.ReactNode>(
    post => (
      <div className="instagram__post">
        <a
          href={`https://instagram.com/p/${post.shortcode}`}
          rel="noopener"
          target="_blank"
          className="instagramPost"
        >
          <div className="instagramPost__header">
            <div className="instagramPost__company">
              <GatsbyImage
                image={company.profile_pic_url}
                className="instagramPost__companyImage"
                alt="Photo from our Instagram!"
              />
              <Text
                type="h6"
                weight="medium"
                color="dark"
                className="instagramPost__companyTitle"
              >
                {company.username}
              </Text>
            </div>
            <MoreIcon />
          </div>
          <GatsbyImage
            image={post.thumbnail_src}
            className="instagramPost__image"
            alt="instagram icon"
          />
          <div className="instagramPost__footer">
            <div className="instagramPost__social">
              <LikeIcon className="instagramPost__icon" />
              <CommentsIcon className="instagramPost__icon" />
              <DirectIcon className="instagramPost__icon-last" />
            </div>
            <BookmarkIcon />
          </div>
        </a>
      </div>
    ),
    [company]
  );

  return (
    <div className="instagram--background">
      <div className="instagram">
        <div className={'posts__container__header'}>
          <Text
            type="h2"
            color="dark"
            weight="bold"
            className={'posts__container__header__title h3'}
          >
            Our Instagram
          </Text>
          <Link
            href={'https://www.instagram.com/stormotion_team/'}
            additionalClassName={'posts__container__header__button'}
          >
            <Button color="transparent" size="small" outlined>
              See more
            </Button>
          </Link>
        </div>
        {posts && (
          <Slider
            className="instagram__posts"
            slidesToShow={width >= 1280 ? 3 : width >= 724 ? 2 : 1}
            slidesToScroll={1}
            speed={500}
            autoplay
            infinite
            arrows={false}
            autoplaySpeed={4000}
          >
            {posts?.map(renderPost)}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default Instagram;
