import * as React from 'react';

const DirectIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20.5 5H3l6.5 6.5m11-6.5l-9 15.5-2-9m11-6.5l-11 6.5"
      stroke="#000"
    />
  </svg>
);

export default React.memo(DirectIcon);
