import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { mapObjIndexed } from 'ramda';

interface Images {
  cn1: IGatsbyImageData;
  cn2: IGatsbyImageData;
  cn3: IGatsbyImageData;
  cn4: IGatsbyImageData;
  cn5: IGatsbyImageData;
  cn6: IGatsbyImageData;
  cn7: IGatsbyImageData;
  cn8: IGatsbyImageData;
  cn9: IGatsbyImageData;
  cn10: IGatsbyImageData;
  cn11: IGatsbyImageData;
}

const useMapCountries = () => {
  const data = useStaticQuery<Images>(graphql`
    {
      cn1: file(relativePath: { eq: "cn1.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn2: file(relativePath: { eq: "cn2.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn3: file(relativePath: { eq: "cn3.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn4: file(relativePath: { eq: "cn4.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn5: file(relativePath: { eq: "cn5.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn6: file(relativePath: { eq: "cn6.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn7: file(relativePath: { eq: "cn7.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn8: file(relativePath: { eq: "cn8.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn9: file(relativePath: { eq: "cn9.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn10: file(relativePath: { eq: "cn10.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      cn11: file(relativePath: { eq: "cn11.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 56
            width: 56
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  return mapObjIndexed(getImage, data);
};

export default useMapCountries;
