import React from 'react';
import Quotes from '../icons/Quotes';
import './style.scss';

type QuoteAvatarProps = {
  Image: JSX.Element;
  className?: string;
};

const QuoteAvatar: React.FC<QuoteAvatarProps> = ({ Image, className }) => {
  return (
    <div className={`avatar ${className}`}>
      {Image}
      <Quotes className={'avatar__quote'} />
    </div>
  );
};

export default React.memo(QuoteAvatar);
