import * as React from 'react';
import Text from '../Text';
import './style.scss';
import useMissionImages from '../../hooks/useMissionImages';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

const Mission: React.SFC = () => {
  const { image, image1, image2, image3 } = useMissionImages();

  return (
    <div className="mission">
      <div className="mission__main">
        <Text
          type="h1"
          color="light"
          weight="bold"
          className={classNames('mission__main__title', 'h3')}
        >
          Stormotion, what’s your mission?
        </Text>
        <Text
          type="h5"
          color="light"
          weight="medium"
          className="mission__main__description"
        >
          We develop Digital Web & App Products, which solve real problems and
          business challenges.
        </Text>
        <Text
          type="p"
          color="light"
          weight="medium"
          className="mission__main__paragraph"
        >
          By emphasizing with our clients and with our experience in product
          development we deliver scalable solutions with a remarkable UX.
        </Text>
        <Text
          type="p"
          color="light"
          weight="medium"
          className="mission__main__paragraph"
        >
          No blind following of your instructions. Led by tech-entrepreneurs,
          our team will co-create the product together with you and think out
          the box to meet your business goals and solve your tech-challenges.
        </Text>
        <div className="mission__overlay">
          <div className="mission__overlay__wrapper">
            <div className="mission__overlay__wrapper__image mission__overlay__wrapper__image--main">
              <GatsbyImage image={image!} alt="We love Stormotion" />
            </div>
            <div className="mission__overlay__wrapper__image mission__overlay__wrapper__image--1">
              <GatsbyImage image={image1!} alt="Our Developers working" />
            </div>
            <div className="mission__overlay__wrapper__image mission__overlay__wrapper__image--2">
              <GatsbyImage image={image2!} alt="The photo of our team" />
            </div>
            <div className="mission__overlay__wrapper__image mission__overlay__wrapper__image--3">
              <GatsbyImage image={image3!} alt="Our CTO Roman" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
