import React, { useCallback } from 'react';
import { WorkflowPointType } from '../../utils/aboutUsTypes';
import WorkflowPoint from '../WorkflowPoint';
import './style.scss';

type WorkflowPointsListProps = {
  workflowPoints: WorkflowPointType[];
};

const WorkflowPointsList: React.FC<WorkflowPointsListProps> = ({
  workflowPoints,
}) => {
  const renderWorkflowPoint = useCallback<
    (point: WorkflowPointType) => JSX.Element
  >((point) => {
    return <WorkflowPoint className="workflow-points__point" {...point} />;
  }, []);

  return (
    <div className="workflow-points">
      {workflowPoints?.map(renderWorkflowPoint)}
    </div>
  );
};

export default React.memo(WorkflowPointsList);
