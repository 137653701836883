import * as React from "react"
import colors from "../../theme/colors"

interface Props {
  className?: string
}

const Quotes: React.SFC<Props> = ({ className }) => (
  <svg width={41} height={43} viewBox="0 0 41 43" className={className}>
    <path
      fill={colors.additional.greyColor.ALTO}
      d="M18 0v5.61c-2.33.71-4.08 2.49-5.25 5.35S11 17.75 11 22.75v1.59h7V43H0V26.77c0-8.24 1.49-14.6 4.48-19.07S11.98.66 18 0zm23 0v5.61c-2.33.71-4.08 2.49-5.25 5.35S34 17.75 34 22.75v1.59h7V43H23V26.77c0-8.24 1.49-14.6 4.48-19.07S34.98.66 41 0z"
    />
  </svg>
)

export default Quotes
