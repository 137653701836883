import * as React from 'react';
import Instagram from '../Instagram';
import Map from '../Map';
import Mission from '../Mission';
import './style.scss';
import QuickFacts from '../QuickFacts';
import { AboutUsPageType } from '../../utils/aboutUsTypes';
import { useTranslation } from 'react-i18next';
import WorkflowPointsList from '../WorkflowPointsList';
import Text from '../Text';
import TeammateFeedbacksList from '../TeammateFeedbacksList';

const AboutUsPage: React.FC<AboutUsPageType> = ({
  teammateFeedbacks,
  workflowPoints,
}) => {
  const { t } = useTranslation();
  return (
    <div className={'about-us'}>
      <div className="about-us__container">
        <Mission />
        <Map />
        <QuickFacts className="about-us-quick-facts" />
        <Text weight="bold" type="h4" className="about-us__subtitle">
          {t('workflow_title')}
        </Text>
        <WorkflowPointsList workflowPoints={workflowPoints} />
        <Text weight="bold" type="h4" className="about-us__subtitle">
          {t('feedback_title')}
        </Text>
        <TeammateFeedbacksList
          teammateFeedbacks={teammateFeedbacks}
          className="careers-page__feedback"
        />

        {/* <Team /> */}
      </div>
      <Instagram />
    </div>
  );
};

export default AboutUsPage;
