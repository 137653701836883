import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { mapObjIndexed } from 'ramda';
import { InstagramCompany, InstagramPost } from '../models/Instagram';

interface InstagramImages {
  company: IGatsbyImageData;
  post1: IGatsbyImageData;
  post2: IGatsbyImageData;
  post3: IGatsbyImageData;
}

const useInstagramPosts = () => {
  const data = useStaticQuery<InstagramImages>(graphql`
    {
      company: file(relativePath: { eq: "instagram-company.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 32
            width: 32
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      post1: file(relativePath: { eq: "instagram-post1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            height: 350
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      post2: file(relativePath: { eq: "instagram-post2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            height: 350
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      post3: file(relativePath: { eq: "instagram-post3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            height: 350
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const images = mapObjIndexed(getImage, data);
  const company: InstagramCompany = {
    profile_pic_url: images.company!,
    username: 'stormotion_team',
    id: 'stormotion_team',
  };
  const posts: InstagramPost[] = [
    {
      shortcode: 'Cyn6NaqNXXT',
      id: 'Cyn6NaqNXXT',
      thumbnail_src: images.post1!,
    },
    {
      shortcode: 'CwSUIKAtwyf',
      id: 'CwSUIKAtwyf',
      thumbnail_src: images.post2!,
    },
    {
      shortcode: 'Cm_9NHoto8w',
      id: 'Cm_9NHoto8w',
      thumbnail_src: images.post3!,
    },
  ];

  return { posts, company };
};

export default useInstagramPosts;
